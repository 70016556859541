@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.hidden {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include media-breakpoint-up(md) {
    gap: 1rem;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.inner {
  display: none;

  @include media-breakpoint-up(md) {
    display: block;
    margin: 0.75rem 0 0;
  }
}

.outer {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
