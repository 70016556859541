@import "../../../styles/variables";
@import "../../../styles/mixins";

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 0.25rem;
  text-align: center;
}

.text {
  color: $slate-500;
  font-size: 14px;
  line-height: 20px;
  margin: 1rem 0 2rem;
  text-align: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
}

.reason {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 125px;
}

.shape {
  align-items: center;
  background-color: $blue-100;
  border-radius: 50%;
  border: 8px solid $blue-50;
  color: $primary;
  display: flex;
  height: 60px;
  justify-content: center;
  margin-bottom: 8px;
  width: 60px;
}

.text {
  color: $gray-500;
  margin: 0;
  text-align: center;
}
