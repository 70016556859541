@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;

  @include media-breakpoint-up(md) {
    justify-content: space-between;
    max-width: 360px;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.text {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;

  @include media-breakpoint-up(md) {
    text-align: center;
  }
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;

  @include media-breakpoint-up(md) {
    text-align: center;
  }
}
