@import "./styles/variables";
@import "./styles/mixins";

.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $gray-100;
}

.appContent {
  flex: 1;
  background-color: $gray-100;
}
