@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

.btn {
  align-items: center;
  display: flex !important;
  gap: 0.5rem;

  &:last-child {
    margin-left: auto;
    flex-direction: row-reverse;
    gap: 0.25rem;

    span:first-child {
      font-size: 20px;
      transform: rotateY(180deg);
    }
  }
}

.text {
  display: none;
  font-size: 14px;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.small {
  @include media-breakpoint-up(md) {
    display: none;
  }
}
