@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.shape {
  align-items: center;
  background-color: rgba($color: $primary, $alpha: 0.25);
  border-radius: 8px;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 60px;
}

.icon {
  font-size: 30px;
  color: $primary;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0 0 0.5rem;
  text-align: center;
}

.error {
  color: $red-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
}
