@import "../../../styles/variables";
@import "../../../styles/mixins";

.text {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
}
.notice{
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
}
