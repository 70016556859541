@import "../../../styles/variables";
@import "../../../styles/mixins";

.header {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.plate {
  display: inline-block;
  border-radius: 5px;
  padding: 0.12rem;
  background: linear-gradient(to bottom, #facb5a 0, #ffdc87 100%);
  box-shadow: 1px 1px 1px #ddd;
  font-size: 24px;
  line-height: 1;
  width: fit-content;
}

.border {
  border: 2px solid #000;
  border-radius: inherit;
  padding: 0.17rem;
}

.text {
  display: inline-flex;
  width: 100%;
  align-items: center;
  color: #000;
}

.dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 2px;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row;
    gap: 1rem;
  }
}

.vehicle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;

  span {
    color: $gray-500;
    font-weight: 400;
  }
}

.date {
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  margin: 0;

  span {
    font-weight: 500;
  }
}
