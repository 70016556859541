@import "../../../styles/variables";
@import "../../../styles/mixins";

.label {
  color: $text-primary;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 0.5rem;
  text-align: left;
}

.text {
  color: $text-secondary;
  text-align: left;
}

.actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 3rem;
}

.btn {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.addon {
  background-color: transparent !important;
  color: $text-secondary !important;
}
