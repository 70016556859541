@import "../../../styles/variables";
@import "../../../styles/mixins";

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
  text-align: center;
}
