@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1.5rem;
  }
}

.icon {
  color: $red-500;
  font-size: 30px;
}

.text {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;

  .title {
    text-align: center;
    margin: 0;

    @include media-breakpoint-up(md) {
      text-align: start;
    }
  }

  .subtitle,
  .hint {
    text-align: center;
    color: $slate-500;
    margin: 0;

    @include media-breakpoint-up(md) {
      text-align: start;
    }
  }

  .hint {
    font-size: 12px;
  }
}

.btn {
  margin-top: 0.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    margin-left: auto;
  }
}
