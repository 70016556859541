@import "../../styles/variables";

.radioButtons {
  display: flex;
}
.radioButton {
  flex: 1;
  input[type="radio"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  label {
    display: block;
    text-align: center;
    cursor: pointer;
    background-color: $gray-600;
    color: #fff;
    padding: 0.5rem 1rem;
    border: 0;
    border-left: 1px solid #fff;
    transition: all 0.25s ease;
    &:hover {
      background-color: $gray-650;
    }
  }
  &:first-child label {
    border-left: 0;
    border-top-left-radius: $btn-border-radius;
    border-bottom-left-radius: $btn-border-radius;
  }
  &:last-child label {
    border-top-right-radius: $btn-border-radius;
    border-bottom-right-radius: $btn-border-radius;
  }
  input[type="radio"]:checked ~ label {
    background-color: $primary;
  }
}
.column {
  flex-direction: column;
  .radioButton {
    label {
      margin-bottom: 0;
      border: 0;
      border-radius: 0;
      border-top: 1px solid #fff;
    }
    &:first-child label {
      border-top: 0;
      border-top-left-radius: $btn-border-radius;
      border-top-right-radius: $btn-border-radius;
    }
    &:last-child label {
      border-bottom-left-radius: $btn-border-radius;
      border-bottom-right-radius: $btn-border-radius;
    }
  }
}
