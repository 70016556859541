@import "../../../../styles/variables";
@import "../../../../styles/mixins";
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;

  @include media-breakpoint-up(md) {
    max-width: 360px;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.subscription {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.plan {
  color: $gray-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

.icon {
  color: $teal-500;
  font-size: 16px;
}

.info {
  align-items: center;
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.element {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;

  &:last-child > div {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }

  svg {
    margin: 0;
    color: $primary;
  }
}

.element + .element {
  border-left: $eb-border;
  padding-left: 2rem;
}

.text {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.hint {
  color: $gray-500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.equal {
  color: $blue-500;
}

.increase {
  color: $teal-500;
}

.decrease {
  color: $red-500;
}
.message{
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.9);
  position:absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.text{
  max-width: 230px;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
  line-height: 28px;
}
.shape {
  align-items: center;
  background-color: $red-100;
  border-radius: 50%;
  border: 4px solid $red-50;
  color: $red-500;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;
}

.shapeIcon{
  align-items: center;
  border-radius: 6px;
  background-color: $violet-100;
  color: $violet-500;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;
}
.concentClose{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.historyWhy{
  color:$primary;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 20px;
}
.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popupContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 550px;
  text-align: left;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  h4{
    margin: 10px 0;
    font-size: 18px;
  }
  p{
    font-size: 16px;
    color: #667085;
  }
}
.popupTitle {
  font-size: 18px;
  margin-bottom: 10px;
}
.closeButton {
  margin: 0;
  color: gray;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
