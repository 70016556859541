@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
}

.date {
  color: $gray-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}
