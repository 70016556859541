@import "../../../styles/variables";
@import "../../../styles/mixins";

.documentButtons {
  margin-bottom: 1rem;
}

.button {
  width: 100%;
  margin-bottom: 1rem;
}

.whatsappIcon {
  width: 16px;
  fill: #25d366;
  margin: 0 3px;
}

.stepButtons {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > div {
    width: 100%;

    &:nth-child(odd) {
      order: 2;
    }

    &:nth-child(even) {
      order: 1;
    }
  }

  @include media-breakpoint-up(ms, $eb-grid-breakpoints) {
    flex-direction: row;

    & > div:nth-child(odd) {
      order: 1;
      width: 40%;
      padding-right: 4px !important;
    }

    & > div:nth-child(even) {
      order: 2;
      width: 60%;
      padding-left: 4px !important;
    }

    & > div > button {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
