@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.report {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.shape {
  align-items: center;
  background-color: #e8e8e8;
  border-radius: 8px;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;
}

.viewed {
  background-color: #e7f8f6;
  color: $teal-500 !important;
}

.grid {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
    justify-content: space-around;
  }
}

.name {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
  min-width: 0;

  span {
    flex: 1;
    color: $gray-500;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.description {
  background-color: transparent !important;
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.content {
  align-items: center;
  display: flex;
  gap: 0.25rem;
}

.text {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.icon {
  color: $primary;
  font-size: 14px;
}

.delete {
  color: $primary;
  cursor: pointer;
  margin: 1rem 0 0;

  @include media-breakpoint-up(md) {
    margin: 0;
  }
}
