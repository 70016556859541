@import "../../styles/variables";
@import "../../styles/mixins";

$bar-height: 0.8rem;
$pointer-width: 0.4rem;
$pointer-height: $bar-height + 0.625rem;
$fake-percentages: 51% 62% 72% 77%;
$percentages: 52% 63% 73% 78%;

// .meter {
//   position: relative;
//   padding-left: 0.75rem;
//   padding-right: 0.75rem;
// }

.bar {
  width: 100%;
  height: $bar-height;
  border-radius: $bar-height / 2;
  background-image: linear-gradient(
    90deg,
    #fd4444 0%,
    #fd4444 calc(100% * 510 / 1000),
    #fcc226 calc(100% * 510 / 1000),
    #fcc226 calc(100% * 620 / 1000),
    #fcc226 calc(100% * 620 / 1000),
    #fcc226 calc(100% * 730 / 1000),
    #3c81c7 calc(100% * 730 / 1000),
    #3c81c7 calc(100% * 770 / 1000),
    #1dcc63 calc(100% * 770 / 1000),
    #1dcc63 100%
  );
  z-index: 10;
}

.pointerContainer {
  position: relative;
  margin: 0 0.5rem;
}

.pointer {
  position: absolute;
  width: $pointer-width;
  height: $pointer-height;
  border-radius: $pointer-width / 2;
  transform: translate(-$pointer-width/2, -($pointer-height - $bar-height) / 2);
  background-color: rgba(0, 0, 0, 0.6);
}

.labels,
.subLabels {
  display: flex;
  justify-content: space-between;
}

.labels {
  margin-bottom: 1.8rem;
}

.subLabels {
  margin-top: 1.8rem;
}

.leftLabel {
  text-align: left;
  padding-right: 0.75rem;
  // margin-left: -0.5rem;
}

.rightLabel {
  text-align: right;
  padding-left: 0.75rem;
  // margin-right: -0.5rem;
}

// @include media-breakpoint-up(xs) {
//   .leftLabel,
//   .rightLabel {
//     margin-left: 0;
//     margin-right: 0;
//   }
// }
// @include media-breakpoint-up(lg) {
//   .leftLabel {
//     margin-left: -0.5rem;
//   }
//   .rightLabel {
//     margin-right: -0.5rem;
//   }
// }
.label {
  font-weight: 300;
}

.subLabel {
  font-size: 0.75rem;
  color: $gray-600;
}

.limit {
  position: relative;
  font-size: 80%;

  div:nth-child(even) {
    transform: translate(0, ($bar-height + 0.28rem));
  }

  div:nth-child(odd) {
    transform: translate(0, -($bar-height + 0.44rem));
  }

  @each $percentage in $fake-percentages {
    div:nth-child(#{index($fake-percentages, $percentage)}) {
      left: $percentage;
      position: absolute;
    }
  }
}

.indicator {
  position: relative;

  div:nth-child(even) {
    transform: translate(0, 0.2rem);
    z-index: 0;
  }

  div:nth-child(odd) {
    transform: translate(0, -0.55rem);
    z-index: 0;
  }

  @each $percentage in $percentages {
    div:nth-child(#{index($percentages, $percentage)}) {
      left: $percentage;
      position: absolute;
    }
  }
}

.speech {
  width: 120px;
  margin: 0;
  border: none;
  padding: 0px;
  text-align: center;
  color: #000;
  position: absolute;
  bottom: 4px;
  -webkit-transform: translate(-50%, 0);
  //transform: translate(-50%, 0);
  @media screen and (max-width: 576px) {
    width: 55px;
    padding: 2px;
    font-weight: 900;
  }
}
.speech_bubble:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  right: 42%;
  bottom: -23px;
  @media screen and (max-width: 576px) {
    right: 34%;
    bottom: -20px;
  }
}

.speech_bubble:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  right: 42%;
  bottom: -18px;
  @media screen and (max-width: 576px) {
    right: 34%;
    bottom: -19px;
  }
}
.card {
  position: relative;
}
.content__meter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  p{
    margin: 0;
  }
}
.svg2 {
  position: absolute;
  left: 0;
  top: -10px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}
.svg2:hover {
  visibility: visible;
  opacity: 1;
}

.ranges {
  fill: $body-color;
  font-size: 9px;
  font-weight: 500;
}

.label {
  fill: $body-color;
  font-size: 36px;
  font-weight: 600;
}
