@import "../../../styles/variables";
@import "../../../styles/mixins";

.security {
  align-items: center;
  background-color: rgba($color: #18b9a7, $alpha: 0.1);
  border-radius: 8px;
  color: #18b9a7;
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  padding: 0.5rem;
  width: 100%;

  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }
}

.icon {
  font-size: 18px;
  margin-right: 0.3rem;
}
