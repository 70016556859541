@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -1rem;
  text-align: center;
}

.banner {
  align-items: center;
  background-color: $primary;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  width: 100%;
}

.icon {
  color: #ffffff;
  font-size: 32px;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 1rem;

  @include media-breakpoint-up(md) {
    font-size: 22px;
  }
}

.wrapper {
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
}

.hint {
  color: $slate-400 !important;
  font-size: 12px;
  text-align: center;
}

.containerCode {
  align-items: center;
  display: flex;
  justify-content: center;
}

.inputCode,
.inputErrorCode {
  width: 2rem !important;
  height: 2rem;
  margin: 0 0.25rem;
  font-size: 1.25rem;
  border-radius: 0.25rem;
  border: 1px solid $slate-400;
}

.inputErrorCode {
  border-color: #dc3545 !important;
}

.invalidField {
  color: #dc3545;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  text-align: center;
  width: 100%;
}

.separatorCode {
  color: $slate-400;
}

.button {
  margin-top: 1.5rem;
}
