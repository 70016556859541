@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
  max-width: 1110px;
}
