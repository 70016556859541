@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}
