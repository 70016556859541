@import "../../../../styles/variables";
@import "../../../../styles/mixins";

$box-size: 1.2rem;
$box-margin: 0.0625rem;

.boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.yearBox {
  display: flex;
  flex-direction: column;
  width: $box-size * 4 + $box-margin * 8;

  .yearTitle {
    color: $gray-500;
    font-size: 14px;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }

  .months {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }

  &:first-child {
    .months {
      align-items: flex-end;
      align-self: flex-end;
    }
  }

  &:last-child {
    .months {
      align-content: flex-start;
    }
  }
}

.box {
  align-items: center;
  background-color: $gray-400;
  border-radius: 4px;
  border: $gray-400;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: $box-size;
  justify-content: center;
  margin: $box-margin;
  width: $box-size;
}

.legend {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  margin-top: 1rem;
}

.grid {
  align-items: center;
  display: flex;
  gap: 0.25rem;
}

.textLegend {
  color: $gray-500;
  font-size: 14px;
  line-height: 14px;
  margin: 0;
}

.tag- {
  background-color: transparent;
  border-width: 0;
}

.tag-N,
.tag-n,
.tag-S,
.tag-T {
  background-color: $teal-500;
  border-color: $teal-500;
  color: #ffffff;
}

.tag-R {
  background-color: $blue-500;
  border-color: $blue-500;
  color: #ffffff;
}

.tag-X {
  background-color: $red-500;
  border-color: $red-500;
  color: #ffffff;
}

.tag-1,
.tag-2,
.tag-3,
.tag-4,
.tag-5,
.tag-6,
.tag-7,
.tag-8,
.tag-9,
.tag-10,
.tag-11,
.tag-12,
.tag-13,
.tag-14,
.tag-M {
  background-color: $red-500;
  border-color: $red-500;
  color: #ffffff;
}
