@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}
