@import "../../../styles/variables";
@import "../../../styles/mixins";

.label {
  color: $text-primary;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 0.5rem;
  text-align: left;
}

.options {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.option {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  padding: 0.4rem 0.2rem !important;
  width: 50% !important;
}

.outline {
  border: 1px solid $field-border !important;
  color: $text-secondary !important;

  &:hover {
    background-color: transparent !important;
    border: 1px solid $field-border !important;
    color: $text-secondary !important;
  }
}
