@import "../../../styles/variables";
@import "../../../styles/mixins";

.plan {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: auto;
  position: relative;

  @include media-breakpoint-up(md) {
    max-width: 335px;
  }
}

.header {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}

.saving {
  align-items: center;
  background-color: $teal-500;
  border-radius: 24px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 0.3rem;
  justify-content: center;
  padding: 0.3rem 1rem;
  text-align: center;
}

.icon {
  font-size: 12px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

.offer {
  color: $yellow-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  text-decoration: line-through;

  @include media-breakpoint-up(md) {
    height: 16px;
  }
}

.price {
  font-size: 22px;
  line-height: 20px;
  margin: 0;

  span {
    color: $gray-500;
    font-size: 16px;
  }
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 0.25rem 0;
}

.btn {
  margin-top: auto;
  width: 100%;
}
