@import "../../styles/variables";
@import "../../styles/mixins";

.logo {
  margin: 1.5rem 0;
  max-width: 200px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 1rem 0;
  margin: 0;
  width: 100%;

  .element:last-child {
    margin-bottom: 0;
  }
}

.element {
  border-radius: 8px;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: $blue-50;
  }

  .content {
    align-items: center;
    color: $gray-500;
    cursor: pointer;
    display: flex;

    .label {
      font-size: 14px;
      font-weight: 600;
      margin: 0 0 0 0.5rem;
    }
  }
}

.star {
  color: $teal-500;
}

.active {
  background-color: $primary;

  &:hover {
    background-color: $primary;
  }

  .content {
    color: #ffffff;
  }
}

.hr {
  border-color: $gray-50;
  margin: 1rem 0;
  width: 100%;
}
