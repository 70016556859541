@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.content {
  display: flex;
  flex-direction: column;
}
