@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  gap: 0.75rem;
  margin: 0 -1rem;
  padding: 18px 1rem;
}

.container + .container {
  border-top: $eb-border;
}

.icon {
  color: $gray-500;
  cursor: pointer;
  margin-left: auto;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.value {
  color: $gray-500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.note {
  flex-direction: column;
  justify-content: center;
  padding-bottom: 0;

  .icon {
    color: $blue-500;
    font-size: 30px;
    margin: 0;
  }

  .info {
    justify-content: center;
  }

  .label,
  .value {
    text-align: center;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 1.5rem;
    justify-content: flex-start;

    .icon {
      font-size: 40px;
    }

    .info {
      justify-content: flex-start;
    }

    .label,
    .value {
      text-align: left;
    }
  }
}
