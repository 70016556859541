@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 -1rem;
  padding: 0 1rem;
}

.container + .container {
  border-top: $eb-border;
  padding-top: 1rem;
}

.content {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 1rem;
}

.shape {
  align-items: center;
  background-color: $gray-150;
  border-radius: 8px;
  color: $gray-500;
  display: flex;
  flex-shrink: 0;
  height: 44px;
  justify-content: center;
  width: 44px;
}

.status {
  font-size: 24px;

  // Background icon
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  height: 15px;
  justify-content: center;
  width: 15px;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @include media-breakpoint-up(md) {
    gap: 0.5rem;
  }
}

.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

.success {
  color: $teal-500 !important;
}

.danger {
  color: $red-500 !important;
}

.normal {
  color: $gray-500 !important;
}

.icon {
  color: $gray-500;
  margin-left: auto;
}
