@import "../../styles/variables";
@import "../../styles/mixins";

.btn {
  align-items: center;
  display: flex !important;
  gap: 0.5rem;
  width: fit-content;
}

.label {
  display: block;
}
