@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
}

.chart {
  flex: 0 0 auto;
  width: 100% !important;
  max-height: 180px !important;
}
