@import "../../../styles/variables";
@import "../../../styles/mixins";

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
}

.text {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.list {
  margin: 0;
  padding-left: 1.5rem;
}

.form {
  display: flex;
  flex-direction: column;
}

.field {
  color: $gray-500;
  width: 100%;

  label {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

.container {
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;
  }
}

.button {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}
