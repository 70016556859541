@import "../../../styles/mixins";
@import "../../../styles/variables";

.banner {
  background: linear-gradient(to top right, #2563eb, #3b82f6);
  border-bottom-left-radius: 100px;
  height: 100%;
  inset: 0;
  max-height: 95%;
  position: absolute;

  @include media-breakpoint-up(md, $eb-grid-breakpoints) {
    max-height: 85%;
  }
}

.card {
  background-color: #fff;
  margin: 0 auto;
  max-width: 430px;
  padding: 2rem 1rem;
  position: relative;
  width: 100%;
}

.title {
  color: $text-primary;
  font-weight: 700;
  margin: 0.5rem 0 1.5rem;
}

.footer {
  color: #979797;
  display: block;
  font-size: 14px;
  margin-top: 3rem;
  padding-bottom: 1rem;
  text-align: center;
}
