@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row-reverse;
    justify-content: left;
  }
}

.button {
  align-items: center;
  display: flex !important;
  justify-content: center;
  gap: 1rem;
  width: 100% !important;
}

.hr {
  border-color: $slate-100 !important;
  margin: 0 !important;
}

.data {
  display: none;

  @include media-breakpoint-up(md) {
    display: block !important;
  }
}

.active {
  display: block;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}


.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
    color: $gray-500;
  }

  th {
    background-color: #f4f4f4;
  }
  @media (max-width: 620px) {
    table, thead, tbody, th, td, tr {
        display: block;
    }

    thead {
        display: none; 
    }

    tr {
        margin-bottom: 10px;
        border: 1px solid #ccc;
        padding: 10px;
    }

    td {
        color: $gray-500;
        display: flex;
        justify-content: space-between;
        padding: 8px;
        border-bottom: 1px solid #ddd;
    }

    td::before {
        content: attr(data-label); /* Agregar nombre de la columna */
        font-weight: bold;
        text-transform: uppercase;
    }
}
}
.section {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}
.section_title {
  background-color: #f5f5f5;
  padding: 5px;
  font-weight: bold;
  margin-bottom: 5px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.cell {
  color: $gray-500;
  flex: 1;
  min-width: 200px;
  padding: 3px 5px;
}
.score {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}
.source{
  font-size: 14px; 
  font-weight: normal; 
  color: #5a5a5a;
}
.total {
  font-weight: bold;
  background-color: #f0f0f0;
}
.element {
  display: flex;
  flex-direction: column;

  .label {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }

  .value {
    color: $gray-500;
    font-size: 14px;
    margin: 0;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    .value {
      margin-left: 0.25rem;
    }
  }
}
