@import "../../../styles/variables";
@import "../../../styles/mixins";

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 1rem;
}

.text {
  border-top: $eb-border;
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0 -1rem;
  padding: 1rem 1rem 0;
}
