@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.text {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
}

.permissions {
  align-items: center;
  border-radius: 8px;
  border: $eb-border;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 10px 14px;
}

.device + .device {
  border-left: $eb-border;
  padding-left: 1rem;
}

.requesting {
  height: 22px !important;
  width: 22px !important;
}
