@import "../../../styles/variables";
@import "../../../styles/mixins";

.contentDocumen{
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    @include media-breakpoint-up(xl) {
        flex-direction: row;
      }
}
.document{
    width: 100%;
    margin-right: 15px;
}
.documentIssueDateEntered{
    min-width: 0;

    @include media-breakpoint-up(xl) {
        min-width: 260px;
      }
}
