@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

// Colors
$gray-50: #f0f1f3;
$gray-150: #f1f5f9;
$gray-250: #e3e3e3;
$gray-350: #e9e9e9;
$gray-500: #667085;
$gray-650: #555555;

$blue-50: #eff6ff;
$blue-100: #dbeafe;
$blue-400: #60a5fa;
$blue-500: #3b82f6;
$blue-600: #2563e8;

$slate-100: #e0e2e7;
$slate-300: #cbd5e1;
$slate-400: #94a3b8;
$slate-500: #64748b;
$slate-600: #475569;
$slate-800: #1e293b;

$teal-500: #14b8a6;
$teal-600: #009d8c;

$red-50: #feedec;
$red-100: #fcdad7;
$red-500: #f04438;

$orange-50: #fdf1e8;
$orange-100: #fae1cf;
$orange-500: #e46a11;

$green-50: #e7f4ee;
$green-100: #cfe7dc;
$green-500: #0d894f;

$violet-50: #efeffd;
$violet-100: #dedefa;
$violet-500: #5c59e8;

$yellow-500: #fbbf24;

$red: $red-500;
$blue: $blue-500;
$primary: $blue-500;
$dark: #333843;

$theme-colors: (
  primary: $blue-500,
  success: #50be50,
  danger: #e44b4b,
  red: $red-500,
  blue: $blue-500,
  dark: $dark
);

// Header
$header-height-mobile: 75px;
$header-height-desktop: 100px;

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Grid breakpoints
$eb-grid-breakpoints: (
  xs: 0,
  ms: 360px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Links
$link-hover-decoration: none;

// Fonts
$font-family-base: "Inter", sans-serif;
$font-size-base: 0.875rem;
$enable-responsive-font-sizes: true;
$font-size-md: $font-size-base * 1.25;

// Form validation
$form-feedback-font-size: $font-size-base;

// Alert
$alert-border-level: 1 !default;

// Custom
$action-color: rgba(55, 53, 47, 0.8);

// Reacstrap
$field-border: #ced4da;

// Site
$text-primary: #32396c;
$text-secondary: #94a3b8;

// Enbanca styles
$eb-border: 1px solid $gray-50;
