@import "../../../styles/variables";
@import "../../../styles/mixins";

.title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 1rem;
}

.container {
  margin: 0 -1rem;
}
