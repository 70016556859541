@import "../../../styles/variables";
@import "../../../styles/mixins";

.invalidField {
  color: #dc3545;
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  margin-top: 0.25rem;
  width: 100%;
}

.content {
  button {
    width: 100% !important;
  }

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;

    button {
      width: auto !important;
    }
  }
}

.text {
  color: $gray-500;
  line-height: 20px;
  margin: 0 0 0.75rem;
}
