@import "../../../styles/variables";

.label {
  color: $text-primary;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 0.5rem;
  text-align: left;
}

.addon {
  background-color: transparent !important;
  color: $text-secondary !important;
}

.btn {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
}

.hint {
  color: $text-secondary;
  font-size: 0.875rem;
  margin: 0;
  text-align: left;
}
