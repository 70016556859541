@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 1.5rem;
  }
}

.shape {
  background-color: #ffffff;
  border: $eb-border;
  border-radius: 8px;
  flex-shrink: 0;
  height: 60px;
  width: 60px;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    height: 90px;
    width: 90px;
  }

  img {
    height: 100%;
    object-fit: cover; // contain
    width: 100%;
  }
}

.offer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: start;
  }
}

.title {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.type {
  color: $gray-500;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.buttons {
  display: flex;
  flex-direction: column-reverse;
  flex: 0 0 auto;
  gap: 0.75rem;
  margin-top: 1rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin-left: auto;
    margin-top: 0;
  }
}

.btn {
  align-items: center;
  display: flex !important;
  gap: 0.25rem;
}

.hr {
  border-color: $gray-50 !important;
  margin: 1rem -1rem;
}

.info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.element {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
}

.value {
  color: $gray-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}
