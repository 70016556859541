@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 2rem;
  }
}

.table {
  margin-bottom: 0 !important;

  tr {
    border-bottom: $eb-border;

    td {
      border-top: none;
      padding: 0.5rem 0.25rem !important;
    }

    td:first-child {
      min-width: 160px;
      padding-left: 0 !important;
    }

    td:last-child {
      padding-right: 0 !important;
    }

    &:first-child {
      td {
        padding-top: 0 !important;
      }
    }
  }
}

.data {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 100%;

  @include media-breakpoint-up(md) {
    width: 50%;
  }
}

.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.text {
  color: $gray-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;

  span {
    font-weight: 600;
  }
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
