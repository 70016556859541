@import "../../../styles/variables";
@import "../../../styles/mixins";

.hr {
  border-color: $gray-50 !important;
  margin: 1rem -1rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0.5rem 0 0;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0 0 0.25rem;
}

.date {
  background-color: rgba($color: #feaeae, $alpha: 0.2);
  border-radius: 8px;
  color: $gray-800;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 0.75rem 0.5rem;
  width: 100%;
}

.success {
  background-color: #e8f8f7 !important;
}
