@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tools {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 1rem;
  }
}

.tool {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  gap: 0.75rem;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.active {
  border: 2px solid $blue-500 !important;
}

.label {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}

.shape {
  align-items: center;
  background-color: $blue-100;
  border-radius: 50%;
  border: 4px solid $blue-50;
  color: $primary;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;
}

.violet {
  background-color: $violet-100 !important;
  border-color: $violet-50 !important;
  color: $violet-500 !important;
}

.green {
  background-color: $green-100 !important;
  border-color: $green-50 !important;
  color: $green-500 !important;
}

.orange {
  background-color: $orange-100 !important;
  border-color: $orange-50 !important;
  color: $orange-500 !important;
}

.red {
  background-color: $red-100 !important;
  border-color: $red-50 !important;
  color: $red-500 !important;
}

.hr {
  border-color: $slate-100 !important;
  margin: 0;
  scroll-margin: 70px;
  width: 100%;
}
