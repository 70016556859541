@import "../styles/variables";
@import "../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.title {
  color: $primary;
  font-size: 50px;
  font-weight: 700;
  line-height: 54px;
  margin: 0;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 0.25rem;
  text-align: center;
}

.text {
  color: $slate-500;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  text-align: center;
}
