@import '../../styles/variables';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0;
  border-radius: $border-radius-lg;
  margin-bottom: 1.25rem;
  .card-footer.card-body {
    background-image: url(../../images/panel-footer-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 93px;
    padding-top: 1.875rem;
    border-radius: $border-radius-lg;
    &.no-content {
      height: 70px;
    }
  }
}
.footer {
  background-image: url(../../images/panel-footer-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 93px;
  padding-top: 1.875rem;
  border-radius: $border-radius-lg;
  &.no-content {
    height: 70px;
  }
}
.smallShadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}
.mediumShadow {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}
.imageColumn {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 150px;
  margin-top: 1rem;
}
.imageContentColumn {
  background-color: $gray-200;
}
