@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 450px;
  margin: 0 auto;
}
