@import "../styles/variables";
@import "../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.remove {
  height: 0;
  transition: height 1s linear;
}

.message {
  align-items: center;
  border-radius: 8px;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin: 1rem 1rem 0;
}

.success {
  background-color: $success;
}

.warning {
  background-color: $warning;
}

.error {
  background-color: $danger;
}

.info {
  background-color: rgba($primary, 0.92);
}

.toast {
  border-radius: 8px;
  padding: 1rem;
  text-align: left;
  color: #ffffff;
}

.icon {
  cursor: pointer;
}

.open {
  animation: fadein 0.5s;
}

.close {
  animation: fadeout 0.5s;
  height: 0;
  padding: 0;
  transition: height ease-out, padding ease-out 0.5s;
  visibility: hidden;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 10px;
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    bottom: 10px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}
