@import "../../../styles/variables";
@import "../../../styles/mixins";

.summary {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.data {
  display: flex;
  flex-direction: column;
}

.value {
  color: $gray-500;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
}
