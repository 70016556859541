@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}
