@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.period {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 -1rem;
  padding: 18px 1rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 1.5rem;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.period + .period {
  border-top: $eb-border;
}

.shape {
  align-items: center;
  background-color: $gray-150;
  border-radius: 8px;
  display: flex;
  height: 60px;
  justify-content: center;
  width: 60px;

  @include media-breakpoint-up(md) {
    height: 90px;
    width: 90px;
  }
}

.icon {
  color: $blue-500;
  font-size: 32px;

  @include media-breakpoint-up(md) {
    font-size: 45px;
  }
}

.details {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  justify-content: center;

  @include media-breakpoint-up(md) {
    justify-content: start;
  }
}

.date {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.text {
  color: $gray-500;
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }

  span {
    color: $body-color;
    font-weight: 600;
  }
}
