@import "../../../styles/variables";
@import "../../../styles/mixins";

input::placeholder {
  color: #94a3b8 !important;
}

.label {
  color: $text-primary;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 0.5rem;
  text-align: center;
}

.containerCode {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.inputCode {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  font-size: 1.25rem;
  height: 36px;
  margin: 0 0.25rem;
  width: 36px !important;
}

.error {
  color: #dc3545;
  margin-bottom: 1rem;
  text-align: center;
}

.divider {
  align-items: center;
  color: $text-secondary;
  display: flex;
  font-size: 1rem;
  font-weight: 500;
  margin: 0.5rem 0;
}

.divider::before,
.divider::after {
  flex: 1;
  content: "";
  padding: 0.5px;
  background-color: $text-secondary;
  margin: 8px;
}

.action {
  color: $text-primary;
  display: block;
  font-weight: 600;
  margin: 0.5rem 0;

  &:hover {
    color: $text-primary !important;
  }
}
