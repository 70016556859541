@import "../../../styles/variables";
@import "../../../styles/mixins";

.title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 1rem;
}

.element {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 1.25rem;
  }
}

.background {
  align-items: center;
  background-color: $gray-150;
  border-radius: 8px;
  color: $primary;
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100px;
}

.icon {
  font-size: 44px;
}

.disabled {
  background-color: $gray-350;
  color: $gray-500;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  span {
    font-weight: 600;
  }
}

.btn {
  @include media-breakpoint-up(md) {
    margin-left: auto;
  }
}

.hr {
  border-color: $gray-50 !important;
  margin: 2rem -1rem !important;
}
