@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 -1rem;
  padding: 1rem 1rem 0;

  &:first-child {
    padding-top: 0;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
    gap: 1.5rem;
  }
}

.container + .container {
  border-top: $eb-border;
}

.shape {
  background-color: #ffffff;
  border-radius: 8px;
  border: $eb-border;
  flex-shrink: 0;
  height: 60px;
  overflow: hidden;
  width: 60px;

  @include media-breakpoint-up(md) {
    height: 90px;
    width: 90px;
  }

  img {
    height: 100%;
    object-fit: cover; // contain
    width: 100%;
  }
}

.grid {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include media-breakpoint-up(md) {
    align-items: flex-start;
  }
}

.lender {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    text-align: left;
  }
}

.info {
  align-items: center;
  color: $blue-500;
  display: flex;
  gap: 0.25rem;

  &:hover {
    color: $blue-500;
  }

  @include media-breakpoint-up(md) {
    margin-left: auto;
  }
}

.text {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  white-space: nowrap;
  margin: 0;
}

.icon {
  font-size: 22px;
}
