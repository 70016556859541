@import "../../styles/variables";
@import "../../styles/mixins";

.navbar {
  background-color: #ffffff;
  border-bottom: 1px solid rgba($color: $gray-500, $alpha: 0.15);
  box-shadow: 0px 4px 30px 0px rgba(#8362ea, 0.05);
  height: 4.5rem;
  padding: 0.5rem 1rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: all 0.3s;
  width: 100%;
  z-index: 999;
}

.container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;

  & > div:only-child {
    margin-left: auto;
  }
}

.toggle {
  align-items: center;
  color: $gray-500;
  cursor: pointer;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  user-select: none;
}

.dropdown {
  margin-right: -1rem;
}

.empty {
  width: 1rem;
}

.profile {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.avatar {
  display: inline-block;
  position: relative;
}

.shape {
  align-items: center;
  background-color: $blue-500;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  width: 32px;
}

.status {
  background-color: #3da172;
  border-radius: 50%;
  border: 2px solid #ffffff;
  bottom: -2px;
  height: 14px;
  position: absolute;
  right: -4px;
  width: 14px;
}

.user {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0.25rem 0 0.75rem;
  text-align: left;
}

.menu {
  border: none !important;
  border-radius: 0 0 0 1rem !important;
  box-shadow: 0px 1.5px 10px 0px rgba(#000000, 0.1);
  font-size: 14px !important;
  margin: 1.24rem 0 0 !important; // 1.25rem
  min-width: 12rem !important;
  padding: 1rem 1rem !important;

  .item:last-child {
    margin-bottom: 0;
  }
}

.item {
  border-radius: 8px;
  margin-bottom: 0.5rem;
  padding: 0.5rem 0.75rem;

  &:hover {
    background-color: $blue-50;
  }

  .content {
    align-items: center;
    color: $gray-500;
    cursor: pointer;
    display: flex;
    gap: 0.5rem;

    .label {
      font-weight: 600;
      margin: 0;
    }
  }
}

.hidden {
  display: none;
}
