@import "../../../styles/variables";
@import "../../../styles/mixins";

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.grid {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
  }
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 1rem;
}

.field {
  color: $gray-500;
  width: 100%;

  label {
    font-size: 14px !important;
    line-height: 20px !important;
  }

  @include media-breakpoint-up(md) {
    width: 50%;
  }
}

.container {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: flex-end;
  }
}

.button {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}
