@import "../../../styles/variables";

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.date,
.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.hr {
  border-color: $slate-100 !important;
  margin: 1rem 0 !important;
}
