@import "../../../styles/variables";
@import "../../../styles/mixins";

.plans {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: center;
  }
}
