@import "../../../styles/variables";
@import "../../../styles/mixins";

.table {
  margin: 0 !important;

  thead {
    background-color: $gray-150;
  }
}

.th {
  border: none !important;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 28px;
  white-space: nowrap;

  &:nth-child(2) {
    min-width: 140px;
  }
}

.tr {
  border-bottom: $eb-border;

  &:hover {
    background-color: $gray-50 !important;
  }

  &:last-child {
    border-bottom: none !important;
  }
}

.td {
  border-top: none !important;
  color: $gray-500;
  font-size: 14px;
  line-height: 28px;
  padding: 1.5rem 0.75rem !important;
}
