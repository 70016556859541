@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  margin: 1rem 0 0;
}

.spinner {
  height: 20px !important;
  width: 20px !important;
}

.text {
  color: $primary;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}
