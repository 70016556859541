@import "../../../styles/variables";
@import "../../../styles/mixins";

.addon > button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.error {
  color: $danger;
  font-size: 12px;
  margin-top: 0.25rem;
}
