@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.text {
  color: $red-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: center;
  }
}

.btn {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}
