.phoneValidation {
  @media (min-width: 890px) {
    display: flex;
  }
}
.button {
  width: 100%;
  margin-top: 0.5rem;
  @media (min-width: 890px) {
    width: 12rem;
    margin-left: 0.5rem;
    margin-top: 0;
  }
}
