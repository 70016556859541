@import "../../../styles/variables";
@import "../../../styles/mixins";

.plan {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @include media-breakpoint-up(md) {
    max-width: 550px;
    padding: 2rem !important;
  }
}

.pro {
  background: linear-gradient(160deg, $blue-500 48%, #81a8e8 94%);
  color: #ffffff;

  .price > span {
    color: #ffffff;
  }
}

.collapsible {
  @include media-breakpoint-up(md) {
    height: fit-content;
  }
}

.header {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.title {
  font-size: 16px;
  line-height: 26px;
  margin: 0;

  @include media-breakpoint-up(md) {
    line-height: 32px;
  }
}

.saving {
  align-items: center;
  background-color: $teal-500;
  border-radius: 24px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  gap: 0.3rem;
  justify-content: center;
  padding: 0.3rem 1rem;
  text-align: center;
}

.icon {
  font-size: 12px;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

.offer {
  color: $yellow-500;
  font-size: 24px;
  line-height: 26px;
  margin: 0;
  text-decoration: line-through;

  @include media-breakpoint-up(md) {
    height: 24px;
  }
}

.price {
  font-size: 28px;
  line-height: 30px;
  margin: 0;

  span {
    color: $gray-500;
    font-size: 16px;
    line-height: 18px;
  }

  @include media-breakpoint-up(md) {
    font-size: 36px;
    line-height: 38px;

    span {
      font-size: 18px;
      line-height: 20px;
    }
  }
}

.profits {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0 auto;
}

.profit {
  display: flex;
  gap: 1rem;
}

.profitIcon {
  color: $teal-500;

  // Background icon
  align-items: center;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  height: 15px;
  justify-content: center;
  width: 15px;
}

.profitText {
  font-size: 14px;
  line-height: 20px;
  margin: -0.15rem 0 0;
}

.profitDisabled {
  color: $slate-300;
}

.highlight {
  align-items: center;
  background-color: $yellow-500;
  border-radius: 2px;
  display: flex;
  height: 18px;
  justify-content: center;
  padding: 0 2px;
}

.highlightIcon {
  color: #ffffff;
  font-size: 14px; // 14px + 4px (padding) = 18px (height)
}

.btn {
  margin-top: 1rem;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-top: 2rem;
  }
}

.btnCollapsible {
  align-items: center;
  display: flex !important;
  gap: 0.25rem;
  justify-content: center;
  width: 100%;
  margin-top: 0.75rem;

  p {
    margin: 0;
  }
}
