@import "../../../styles/variables";
@import "../../../styles/mixins";

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 1rem;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
}

.element {
  margin: 0 -1rem;
  padding: 0.5rem 1rem;
  border-bottom: $eb-border;
}

.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0 0 0.25rem;
}

.value {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.approved {
  color: $teal-500;
}

.rejected {
  color: $red-500;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin: 1rem 0 0;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: flex-end;
  }
}

.button {
  width: 100%;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}
