@import '../../styles/_variables.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.loader {
  width: 40px;
  height: 40px;
  border: 4px solid $gray-300;
  border-top-color: $primary;
  border-radius: 50%;
  animation: loader-spin infinite 1s linear;
}

@keyframes loader-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.siteLoader {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.siteLoaderMessage {
  color: $gray-600;
  text-align: center;
}
