@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 100vw;
  padding: 1rem;
  width: 100%;
}
