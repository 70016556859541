@import "../../../styles/variables";
@import "../../../styles/mixins";

.box {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: -1rem;
  text-align: center;
}

.banner {
  align-items: center;
  background-color: rgba($color: $teal-500, $alpha: 0.75);
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: center;
  padding: 0.75rem;
  width: 100%;
}

.icon {
  color: #ffffff;
  font-size: 32px;
}

.wrapper {
  font-size: 1rem;
  padding: 1rem;
  width: 100%;
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 1rem;

  @include media-breakpoint-up(md) {
    font-size: 22px;
  }
}

.teaser {
  font-size: 14px;
  margin: 0 0 0.25rem;

  span {
    font-weight: 600;
  }

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

.description {
  font-size: 14px;
  margin: 0;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

.button {
  margin-top: 1.5rem;
}
