// #electronic-id-modal {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100vw;
//   height: 100vh;
//   background-color: rgba(255, 255, 255, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   #electronic-id-video {
//     width: 100vw;
//     height: 100vh;
//     max-width: 600px;
//   }
// }

.modal-content {
  border-radius: 8px !important;
}
