@import "./variables";

$button-border-radius: 8px;

.btn:focus {
  box-shadow: none !important;
}

.btn-rounded {
  border-radius: $button-border-radius;
}

.btn {
  border-radius: 8px !important;
  font-weight: 500 !important;
}

.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;

  &:hover {
    background-color: $blue-600 !important;
  }
}

.btn-outline-primary {
  background-color: #ffffff;
  border-color: $primary !important;
  $color: $primary !important;

  &:hover {
    background-color: $primary !important;
    $color: #ffffff;
  }
}

.btn-outline-secondary {
  background-color: #ffffff;
  border-color: $slate-100 !important;
  color: $gray-500 !important;

  &:hover {
    background-color: $gray-600 !important;
    border-color: $gray-600 !important;
    color: #ffffff !important;
  }
}
