@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.icon {
  color: $slate-400;
  font-size: 52px;
  margin: 0;
}

.text {
  color: $slate-500;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  text-align: center;
}
