@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  background-color: #ebf3fe;
  border-bottom: $eb-border;
  border-top: $eb-border;
  margin: 0 -1rem;
  padding: 1rem;
}

.title {
  color: $gray-500;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

.content {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.shape {
  align-items: center;
  background-color: $gray-150;
  border-radius: 8px;
  color: $gray-500;
  display: flex;
  flex-shrink: 0;
  font-size: 24px;
  height: 44px;
  justify-content: center;
  width: 44px;
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @include media-breakpoint-up(md) {
    gap: 0.5rem;
  }
}

.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 0;
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

.icon {
  color: $gray-500;
  cursor: pointer;
  margin-left: auto;
}
