@import '../../styles/variables';
@import '../../styles/mixins';

.validatePending.validatePending {
  margin: 1rem;
  margin-bottom: -1rem;
}
.inlineFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > * {
    margin-bottom: 0.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
    align-items: baseline;
    & > * {
      margin-bottom: 0;
    }
  }
}
.form {
  padding: 0 1rem;
}
.inlineForm {
  display: flex;
}
.field {
  margin-right: 0.5rem;
}
.error.error {
  display: block;
}
