@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
}

.text {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.hr {
  border-color: $gray-50 !important;
  margin: 0 -1rem;
}

.risk {
  background-color: $gray-50;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 1rem;

  @include media-breakpoint-up(md) {
    padding: 1.5rem 1rem;
  }
}

.low {
  background-color: rgba($teal-500, 0.1);

  .label {
    color: $teal-500;
  }

  .riskBar {
    background-color: $teal-500;
    width: 15%;
  }
}

.mediumLow {
  background-color: rgba($blue-500, 0.1);

  .label {
    color: $blue-500;
  }

  .riskBar {
    background-color: $blue-500;
    width: 30%;
  }
}

.medium {
  background-color: rgba($yellow-500, 0.1);

  .label {
    color: $yellow-500;
  }

  .riskBar {
    background-color: $yellow-500;
    width: 50%;
  }
}

.mediumHigh {
  background-color: rgba($yellow-500, 0.1);

  .label {
    color: $yellow-500;
  }
  .riskBar {
    background-color: $yellow-500;
    width: 70%;
  }
}

.high {
  background-color: rgba($red-500, 0.1);

  .label {
    color: $red-500;
  }

  .riskBar {
    background-color: $red-500;
    width: 85%;
  }
}

.bar {
  background-color: #ffffff;
  border-radius: 18px;
  height: 12px;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: 20px;
  }
}

.riskBar {
  background-color: $gray-50;
  border-radius: 18px;
  height: 100%;
  width: 50%;
}

.label {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin: 0;
}

.date {
  color: $gray-500;
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  margin: 0;
}
