@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.content{
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.text {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
}
.notice{
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: center;
}
.message{
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

