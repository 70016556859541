@import "../../../styles/variables";
@import "../../../styles/mixins";

.creditItemCard {
  width: 100%;
  margin-bottom: 0;
}
.creditItem {
  width: 100%;
}
.summary {
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-between;
  }
}
@include media-breakpoint-up(sm) {
  .head {
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
  }
}
.title,
.subtitle,
.group {
  text-align: center;
}
.title {
  color: $primary;
  margin-bottom: 0.25rem;
  @include media-breakpoint-up(sm) {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
}
.subtitle {
  font-weight: 300;
  font-size: 1.125rem;
}
.group {
  font-weight: 300;
  @include media-breakpoint-up(sm) {
    text-align: left;
  }
}
.image {
  max-width: 10rem;
  max-height: 6rem;
  height: auto;
  margin-bottom: 1rem;
  align-self: center;
}
.description {
  margin-bottom: 1rem;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  padding-top: 1rem;
  border-top: 1px solid $gray-300;
  @include media-breakpoint-up(sm) {
    & > * {
      margin-left: 1rem;
    }
  }
}
.clipboard {
  color: #ccc;
  &:hover {
    color: #555;
    cursor: pointer;
  }
}
.urlInput {
  position: absolute;
  left: -9999px;
}
.moreInfoButton {
  color: $primary;
  position: relative;
  margin-right: 1rem;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    right: -0.75rem;
    top: 0.5rem;
    width: 0;
    height: 0;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-top: 0.375rem solid $primary;
    transition: transform 0.25s ease;
  }
  &.open:after {
    transform: rotate(180deg);
  }
}
.moreInfo {
  margin-top: 1rem;
}
.minimumRequirements,
.feeType,
.associatedCosts {
  margin-bottom: 1.5rem;
}
.moreInfo > *:last-child {
  margin-bottom: 0;
}
.minimumRequirementsText,
.feeTypeText,
.associatedCostsText {
  white-space: pre-line;
}
