@import "../../../styles/variables";
@import "../../../styles/mixins";

.registerInfo {
  position: absolute;
  transform: translate(0, -100%);
  padding: 1rem;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.logo {
  width: 50px;
  margin: 0 auto;
}

.title {
  margin: 1rem 0 2rem;
}

.divider {
  width: 3rem;
  border-bottom: 2px solid #fff;
  margin: 0 auto 2rem;
}

.text {
  font-size: 1.1rem;

  @include media-breakpoint-up(ms, $eb-grid-breakpoints) {
    font-size: 1.25rem;
  }
}
