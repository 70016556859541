@import "./styles/variables";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
// @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Roboto:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&family=Roboto:wght@300;400;700&display=swap");
@import "~bootstrap/scss/bootstrap.scss";
@import "./styles/mixins";
@import "./styles/alert";
@import "./styles/buttons";
@import "./styles/elements";
@import "./styles/lists";
@import "./styles/modal";
@import "./styles/zippsid";

html {
  scroll-behavior: smooth;
}
html,
body,
#root {
  height: 100%;
}

.eb-card {
  background-color: white;
  border-radius: 8px;
  border: 1px solid $slate-100;
  box-shadow: 0 1.5px 2px 0 rgba(#101828, 0.1);
  padding: 1rem;
  text-align: left;
  width: 100%;
}

//Global enbanca styles
.enbanca-rounded-border {
  border-radius: 0.8rem;
}

.bg-enbanca {
  background: #0e54fb;
  background: linear-gradient(101deg, #0e54fb 0%, #0ddbbb 100%);
}

.rounded-right {
  border-radius: 0.8rem 0px 0px 0.8rem;
}

.border-2 {
  border-width: 2px !important;
}

.btn-teal {
  background-color: $teal-500 !important;
  border-color: $teal-500 !important;
  color: #ffffff !important;

  &:hover {
    background-color: $teal-600 !important;
    border-color: $teal-600 !important;
  }
}

/* Menu styles */
#App {
  /* Give app full page to work with */
  height: 100vh;
}

/* Individual item */
.bm-item {
  display: inline-block;

  /* Our sidebar item styling */
  text-decoration: none;
  transition: color 0.2s;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  box-shadow: #373a47;
  position: fixed;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 22px;
  width: 70px;
  height: 70px;
  left: 17px;
  top: 17px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #373a47;
}

/* General sidebar styles */
.bm-menu {
  background: #ffffff;
  padding: 0.5rem 1.5rem 0; // Override padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;

  // Override
  align-items: center;
  display: flex;
  flex-direction: column;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
