@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.red {
  color: $red-500;
  fill: $red-500;
}

.green {
  color: $teal-500;
  fill: $teal-500;
}
