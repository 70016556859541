@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.user {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.shape {
  align-items: center;
  background-color: rgba($color: $primary, $alpha: 0.25);
  border-radius: 8px;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;
}

.grid {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.25rem;
}

.name {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
}

.message {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
}

.icon {
  color: $gray-500;
}

.hr {
  border-color: $slate-100 !important;
  margin: 1rem -1rem !important;
}
