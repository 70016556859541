@import "../../styles/variables";
@import "../../styles/mixins";

.row {
  display: flex;
  border-radius: inherit;
  flex-direction: column;
  min-height: 807px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    height: 807px;
  }
}

.img {
  width: 100%;
  z-index: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scaleX(-1);
  background-position: 50% top;
  background-image: url("./../../images/banner6.jpg");

  @include media-breakpoint-up(md) {
    background-position: 65% top;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.85;
    background: rgba(0, 0, 0, 0)
      linear-gradient(rgb(29, 137, 249) 0%, rgb(10, 86, 209) 100%) repeat scroll
      0% 0%;
    // background: rgb(14, 81, 252);
    // background: linear-gradient(180deg, rgba(14, 81, 252, 1) 0%, rgba(13, 216, 188, 1) 100%);
  }
}

.colInfo {
  overflow: hidden;
  width: 100%;
  height: 380px;
  position: relative;
  order: 2;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;

  @include media-breakpoint-up(md) {
    order: 1;
    width: 50%;
    height: 100%;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: 0;
  }
}

.colRegister {
  margin-top: auto;
  margin-bottom: auto;
  flex: 1 0 50%;
  order: 1;
  padding: 1.5rem 3rem;

  @include media-breakpoint-up(md) {
    order: 2;
  }
}

.title {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}

.subtitle {
  margin-bottom: 1rem;
}

.action {
  color: $action-color;
}

.action a {
  color: $action-color;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #5093ff;
    text-decoration: underline;
  }
}
