@import "../../styles/variables";
@import "../../styles/mixins";

.sidebar {
  align-items: center;
  background: #ffffff;
  border-right: 1px solid rgba($color: $gray-500, $alpha: 0.15);
  box-shadow: 4px 0px 30px 0px rgba(#8362ea, 0.05);
  display: none;
  flex-direction: column;
  height: 100vh;
  max-width: 280px;
  min-width: 280px;
  padding: 0 1.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: all 0.3s;
  z-index: 999;

  @include media-breakpoint-up(md) {
    display: flex;
  }
}

.hidden {
  margin-left: -280px;
}
