@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  display: flex;
  max-width: 100vw;
  padding: 1rem 1rem 0;
  width: 100%;
}

.content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;

  @include media-breakpoint-up(md) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1.5rem;
  }
}

.badge {
  display: inline-block;
  position: relative;
  width: fit-content;

  .icon {
    color: $primary;
    font-size: 30px;
  }

  .status {
    background-color: $warning;
    border-radius: 50%;
    border: 2px solid #ffffff;
    bottom: 6px;
    height: 16px;
    position: absolute;
    right: -6px;
    width: 16px;
  }
}

.text {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 20px;

  .title {
    text-align: center;
    margin: 0;

    @include media-breakpoint-up(md) {
      text-align: start;
    }
  }

  .subtitle {
    text-align: center;
    color: $slate-500;
    margin: 0;

    @include media-breakpoint-up(md) {
      text-align: start;
    }
  }
}

.btn {
  margin-top: 0.5rem;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    margin-left: auto;
  }
}
