@import "../../styles/variables";
@import "../../styles/mixins";

.field {
  :global {
    .form-control {
      box-shadow: none;

      &:focus,
      &:hover {
        border-color: $primary;
      }

      &.is-invalid {
        box-shadow: none;
        border-color: $danger;
      }
    }
    input[type="date"] {
      text-transform: uppercase;
      color:$slate-600;
    }
  }

  .label {
    font-weight: 400;
    font-size: $font-size-md;
  }

  .input {
    font-size: $font-size-md;
  }
}

.submitButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  font-size: $font-size-md;
}

.iconLoading,
.iconSuccess {
  font-size: 1.25rem;
}

.description {
  color: $gray-700;
}

.radioFormGroups {
  padding-top: calc(#{$input-padding-y-lg} + #{$input-border-width});
}

.errorFeedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: $danger;
}
