@import "../../../styles/variables";

.label {
  color: $text-primary;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 0.5rem;
}

.containerCode {
  display: flex;
  justify-content: center !important;
}

.inputCode {
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  font-size: 1.25rem;
  height: 36px;
  margin: 0 0.25rem;
  width: 36px !important;
}

.btn {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
}
