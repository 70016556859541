@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;

  @include media-breakpoint-up(md) {
    align-items: center;
    padding: 4rem 4.75rem !important;
  }
}

.picture {
  align-self: center;
  width: 40px;
}

.title {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  margin: 0;

  @include media-breakpoint-up(md) {
    line-height: 28px;
  }
}

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 20px;
  margin: 0;

  @include media-breakpoint-up(md) {
    max-width: 480px;
    text-align: center;
  }
}

.button {
  width: 100%;

  @include media-breakpoint-up(md) {
    padding: 0.375rem 3rem !important;
    width: auto;
  }
}

.benefits {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin: 1rem 0;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    margin: 2rem 0 0;

    .benefit:first-child(-n + 2) {
      .text {
        max-width: 135px;
      }
    }

    .benefit:nth-child(2) {
      .text {
        max-width: 100px;
      }
    }
  }
}

.benefit {
  align-items: center;
  display: flex;
  gap: 0.75rem;
}

.violet {
  color: $violet-500;
}

.green {
  color: $green-500;
}

.blue {
  color: $blue-500;
}

.red {
  color: $red-500;
}

.orange {
  color: $orange-500;
}

.text {
  color: $gray-500;
  font-size: 14px;
  margin: 0;

  @include media-breakpoint-up(md) {
    max-width: 150px;
  }
}
