@import "../../styles/variables";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.title {
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 0.5rem;
  text-align: center;
}

.description {
  color: $gray-500;
  font-size: 14px;
  margin: 0;
  text-align: center;
}
