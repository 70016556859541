@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 -1rem;
  padding: 1rem;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.container + .container {
  border-top: $eb-border;
}

.content {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.shape {
  align-items: center;
  background-color: rgba($color: $blue-500, $alpha: 0.25);
  border-radius: 8px;
  color: $blue-500;
  display: flex;
  height: 44px;
  justify-content: center;
  width: 44px;
}

.overdue {
  background-color: $red-100;
  color: $red-500;
}

.label {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
}

.icon {
  color: $gray-500;
  margin-left: auto;
}
