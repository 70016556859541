@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.container {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: auto;

  @include media-breakpoint-up(md) {
    grid-template-columns: auto auto;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.shape {
  align-items: center;
  background-color: $violet-100;
  border-radius: 8px;
  color: $violet-500;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
}

.icon {
  font-size: 28px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  margin: 0;
}

.info {
  color: $gray-500;
  font-size: 14px;
  line-height: 22px;
}

.description {
  margin: 0;
}

.items {
  margin: 0;
  padding-left: 1.75rem;
}
