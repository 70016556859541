@import "../../../styles/variables";
@import "../../../styles/mixins";

.description {
  color: $gray-500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 1rem 0 0.5rem;
}

.option {
  width: 100%;
}
