@import "../../../styles/variables";
@import "../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.methods {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.method {
  align-items: center;
  background-color: $gray-100;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.shape {
  background-color: #ffffff;
  border: $eb-border;
  border-radius: 8px;
  flex-shrink: 0;
  height: 60px;
  width: 60px;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    // height: 90px;
    // width: 90px;
  }

  img {
    height: 100%;
    object-fit: cover; // contain
    width: 100%;
  }
}

.selected {
  border: 2px solid $primary;
}

.info {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: 700;
  margin: 0;
}

.options {
  color: $gray-500;
  margin: 0;
}
